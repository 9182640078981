<template>
  <div>
    <applicationForm
      :operative-id="operativeId"
    />
  </div>
</template>

<script>
import applicationForm from '@/components/application/ApplicationForm.vue'
import { mapGetters } from 'vuex'

export default {
  middleware: ['check-auth', 'auth'],
  components: {
    applicationForm,
  },
  data() {
    return {
      isMobile: false,
      operativeId: -1,
    }
  },
  computed: {
    ...mapGetters({ opInfo: 'applicants/getCurrentApplication' }),
  },
  created() {
    this.$store.dispatch('app/getTrades')
    this.operativeId = Number(this.$route.query.operativeid)
    /* eslint-disable */
    if (/iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent) || screen.availWidth < 480) {
      this.isMobile = true
    }
    /* eslint-enable */
  },
}
</script>

<style scoped>
 .application-header {
    display: flex;
    width:100%;
    margin:2em;
    margin-bottom:3em;
  }
  .application-header-message {
    margin-left: 2em;
    margin-top: auto;
    margin-bottom: 0;
  }
 .application-footer {
    display: flex;
    width:100%;
    margin:2em;
    padding-bottom:1em;
  }
</style>
