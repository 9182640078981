<template>
  <div>
    <b-row class="mt-2 mb-1">
      <h2 class="ml-1">
        Personal information
      </h2>
      <b-col />
      <b-col>
        <div class="float-right">
          <b-button
            variant="primary"
            block
            @click="onAcceptApplicantClick"
          >
            Accept
          </b-button>
        </div>
        <div class="float-right mr-2">
          <b-button
            v-if="opInfo.status=='REJECTED'"
            variant="outline-primary"
            block
            class="ml-1 float-right"
            @click="onReCallApplicantClick"
          >
            Recall
          </b-button>
          <b-button
            v-else
            variant="outline-danger"
            block
            class="ml-1 float-right"
            @click="onRejectApplicantClick"
          >
            Reject
          </b-button>
        </div>
        <div class="float-right mr-2">
          <b-button
            variant="outline-secondary"
            block
            class="float-left"
            @click="onBackToApplicantsClick"
          >
            Cancel
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="2">
        <b-form-group
          label="Title"
          label-for="title-option"
        >
          <b-form-select
            v-model="title"
            name="title-option"
            :options="titleOptions"
            class="text-uppercase"
            value-field="item"
            text-field="name"
          />
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group label-for="forename-edit">
          <template v-slot:label>
            <span class="required-field">Firstname</span>
          </template>
          <b-form-input
            v-model="firstname"
            name="forename-edit"
            class="text-uppercase"
            :state="firstnameState"
            required
            placeholder="Joe"
          />
          <b-form-invalid-feedback :state="firstnameState">
            Enter a firstname
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group label-for="surname-edit">
          <template v-slot:label>
            <span class="required-field">Lastname</span>
          </template>
          <b-form-input
            v-model="lastname"
            validate="'required'"
            name="surname-edit"
            class="text-uppercase"
            :state="lastnameState"
            required
            placeholder="Bloggs"
          />
          <b-form-invalid-feedback :state="lastnameState">
            Enter a lastname
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="2">
        <b-form-group label-for="dob-date">
          <template v-slot:label>
            <span class="required-field">Date of birth</span>
          </template>
          <b-form-datepicker
            id="dob-date"
            v-model="dateOfBirth"
            selected-variant="primary"
            show-decade-nav
            reset-button
            close-button
            locale="en-GB"
            :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
            :state="dobState"
            required
            placeholder=""
          />
          <b-form-invalid-feedback :state="dobState">
            Enter a date of birth
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col sm="2">
        <b-form-group label-for="nino-edit">
          <template v-slot:label>
            <span class="required-field">N.I. Number</span>
          </template>
          <b-form-input
            v-model="niNumber"
            class="text-uppercase"
            required
            placeholder="AA000000A"
            name="nino-edit"
            :state="ninoState"
          />
          <b-form-invalid-feedback :state="ninoState">
            Enter a National Insurance Number
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group
          label="Nationality"
          label-for="nationality-option"
        >
          <b-form-select
            v-model="nationality"
            name="nationality-option"
            :options="countryOptions"
            class="text-uppercase"
            value-field="code"
            text-field="name"
            placeholder="GB"
          />
        </b-form-group>
      </b-col>
      <b-col sm="5">
        <b-form-group
          label="Worker permit"
          label-for="work-permit-radio"
        >
          <b-form-radio-group
            v-model="workPermit"
            :options="workPermitOptions"
            name="work-permit-radio"
            class="mt-1"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      v-if="opInfo.status=='REJECTED'"
      class="mt-2"
    >
      <h2 class="ml-1">
        Rejection notes
      </h2>
    </b-row>
    <b-row
      v-if="opInfo.status=='REJECTED'"
      class="mt-2"
    >
      <b-col sm="8">
        <b-form-group label-for="rejected-notes">
          <template v-slot:label>
            {{ rejectedDate }}
          </template>
          <b-form-textarea
            v-model="rejectedNotes"
            name="rejected-notes"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div>
      <b-modal
        ref="reject-notes-modal"
        hide-footer
        title="Rejection reasons"
        size="md"
      >
        <b-form-group label-for="rejected-notes-to-save">
          <template v-slot:label>
            <span class="required-field">Notes on rejection</span>
          </template>
          <b-form-textarea
            v-model="rejectedNotesToSave"
            name="rejected-notes-to-save"
          />
        </b-form-group>
        <b-button
          class="mt-2 col-sm-2 ml-auto mr-auto"
          variant="primary"
          block
          @click="onRejectSaveClick"
        >
          Save
        </b-button>
      </b-modal>
    </div>
  </div>
</template>

<script>

import {
  BFormGroup, BFormInput, BRow, BCol, BFormSelect, BFormDatepicker, BFormRadioGroup, BFormInvalidFeedback, BButton, BModal, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import { generalMethods } from '@/mixins/generic-functions'

export default {
  name: 'AppPersonalInfoSection',
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BFormSelect,
    BFormDatepicker,
    BFormRadioGroup,
    BFormInvalidFeedback,
    BButton,
    BModal,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      workPermitOptions: [
        { item: 'Yes', name: 'Yes' },
        { item: 'No', name: 'No' },
        { item: 'Not required', name: 'Not required' },

      ],
      countryOptions: generalMethods.getCountryOptions(),
      titleOptions: generalMethods.getTitleOptions(),
    }
  },
  computed: {
    ...mapGetters({ opInfo: 'applicants/getCurrentApplication' }),
    ...mapGetters({ applicationValidation: 'applicants/getApplicationValidation' }),
    title: {
      get() {
        return this.opInfo.title
      },
      set(value) {
        this.$store.commit('applicants/setApplicationTitle', value)
      },
    },
    firstnameState() {
      if (this.applicationValidation == null) return null

      if (this.firstname === null || this.firstname === undefined || this.firstname.length === 0) {
        this.$store.commit('applicants/failApplicationValidation')
        return false
      }
      return true
    },
    firstname: {
      get() {
        return this.opInfo.firstname
      },
      set(value) {
        this.$store.commit('applicants/setApplicationFirstname', value)
      },
    },
    lastnameState() {
      if (this.applicationValidation == null) return null

      if (this.lastname === null || this.lastname === undefined || this.lastname.length === 0) {
        this.$store.commit('applicants/failApplicationValidation')
        return false
      }
      return true
    },
    lastname: {
      get() {
        return this.opInfo.lastname
      },
      set(value) {
        this.$store.commit('applicants/setApplicationLastname', value)
      },
    },
    dobState() {
      if (this.applicationValidation == null) return null

      if (this.dateOfBirth === null || this.dateOfBirth === undefined || this.dateOfBirth.length === 0) {
        this.$store.commit('applicants/failApplicationValidation')
        return false
      }
      return true
    },
    dateOfBirth: {
      get() {
        // New operative setup, so this will enforce a DOB
        if (this.opInfo.dob === 'new') {
          return ''
        }
        // Existing operatives will have null if no date set, to prevent it being compulsory setting blank date
        if (this.opInfo.dob === null) {
          return '    -  -  '
        }
        return this.opInfo.dob
      },
      set(value) {
        this.$store.commit('applicants/setApplicationDateOfBirth', value)
      },
    },
    ninoState() {
      if (this.applicationValidation == null) return null

      if (this.ninumber === null || this.ninumber === undefined || this.ninumber.length === 0) {
        this.$store.commit('applicants/failApplicationValidation')
        return false
      }
      return true
    },
    niNumber: {
      get() {
        return this.opInfo.ninumber
      },
      set(value) {
        this.$store.commit('applicants/setApplicatoinNINumber', value)
      },
    },
    nationality: {
      get() {
        return this.opInfo.nationality
      },
      set(value) {
        this.$store.commit('applicants/setApplicationNationality', value)
      },
    },
    workPermit: {
      get() {
        if (this.opInfo.operativedetails[0] !== undefined) {
          return this.opInfo.operativedetails[0].workpermit
        }
        return ''
      },
      set(value) {
        this.$store.commit('applicants/setApplicationWorkPermit', value)
      },
    },
    rejectedNotesToSave: {
      get() {
        if (this.opInfo.operativedetails[0] !== undefined) {
          return this.opInfo.operativedetails[0].notes
        }
        return ''
      },
      set(value) {
        this.$store.commit('applicants/setApplicationNotes', value)
      },
    },
    rejectedNotes: {
      get() {
        if (this.opInfo.operativedetails[0] !== undefined) {
          return this.opInfo.operativedetails[0].notes
        }
        return ''
      },
      set(value) {
        this.$store.commit('applicants/setApplicationNotes', value)
      },
    },
    rejectedDate() {
      if (this.opInfo.operativedetails[0] === undefined) return ''
      if (this.opInfo.operativedetails[0].rejectedon === undefined) return ''
      if (this.opInfo.operativedetails[0].rejectedon === '') return ''

      return `${this.opInfo.operativedetails[0].rejectedon.slice(8, 11)}/${this.opInfo.operativedetails[0].rejectedon.slice(5, 7)}/${this.opInfo.operativedetails[0].rejectedon.slice(0, 4)}`
    },
  },
  created() {
  },
  methods: {
    onBackToApplicantsClick() {
      this.$router.go(-1) // Return to previous page
    },
    onRejectApplicantClick() {
      this.$refs['reject-notes-modal'].show()
    },
    onRejectSaveClick() {
      this.$store.dispatch('applicants/rejectApplicant', { operativeid: this.opInfo.ent_id })
    },
    onReCallApplicantClick() {
      this.$store.dispatch('applicants/recallApplicant', { operativeid: this.opInfo.ent_id })
    },
    onAcceptApplicantClick() {
      this.$store.dispatch('applicants/acceptApplicant', { operativeid: this.opInfo.ent_id })
    },
  },
}
</script>

<style scoped>

</style>
