<template>
  <div>
    <b-card>
      <b-form>
        <appPersonalInfoSection />
        <appContactInfoSection />
        <appAddressInfoSection />
        <appTradingInfoSection />
        <appSubmissionSection />
      </b-form>
    </b-card>
  </div>
</template>

<script>

import {
  BForm, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import appPersonalInfoSection from '@/components/application/applicationsections/AppPersonalInfoSection.vue'
import appContactInfoSection from '@/components/application/applicationsections/AppContactInfoSection.vue'
import appAddressInfoSection from '@/components/application/applicationsections/AppAddressInfoSection.vue'
import appTradingInfoSection from '@/components/application/applicationsections/AppTradingInfoSection.vue'
import appSubmissionSection from '@/components/application/applicationsections/AppSubmissionSection.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'OperativeEditorForm',
  components: {
    BForm,
    BCard,
    appPersonalInfoSection,
    appContactInfoSection,
    appAddressInfoSection,
    appTradingInfoSection,
    appSubmissionSection,
  },
  directives: {
    Ripple,
  },
  props: {
    operativeId: {
      type: Number,
      required: true,
      default: -1,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({ applicationValidation: 'applicants/getApplicationValidation' }),
  },
  async created() {
    this.$store.commit('applicants/resetApplicationValidation')
    if (this.operativeId > -1) {
      try {
        await this.$store.dispatch('applicants/getCurrentApplication', { operativeid: this.operativeId })
      } catch (err) {
        console.debug('Error occurred when calling API: ', err.message)
        throw err
      }
    } else {
      this.$store.dispatch('applicants/resetCurrentApplication')
    }
  },
  methods: {
  },
}
</script>

<style scoped>

</style>
